

export default class ImageZoom {
  
  constructor({
    selector = null,
    additionalClass = null,
    srcAttr = null,
    additionalConfig = {},
    disabledMobile = true
  }) {
    this.selector = selector;
    this.additionalClass = additionalClass;
    this.initDOMClass = 'zoom-initialized';
    this.srcAttr = srcAttr;
    this.additionalConfig = additionalConfig;
    this.disabledMobile = disabledMobile;

    if(!selector && !srcAttr) {
      throw new Error('selector and srcAttr are required');
    }

    this.$element = $(this.selector);
  }

  setDomElement() {
    this.$element = $(this.selector);
  }

  update() {
    this.destroy();
    this.setDomElement();
    this.create();
  }

  create() {
    const $el = this.$element;

    if(this.disabledMobile && prestashop.responsive.mobile) {
      return;
    }

    if(!$el.hasClass(this.initDOMClass)) {
      $el.zoom({
        url: $el.attr(this.srcAttr),
        ...this.additionalConfig
      });
      $el.addClass(this.initDOMClass)

      if(this.additionalClass) {
        $el.addClass(this.additionalClass);
      }
    }
  }

  destroy() {
    const $el = this.$element;

    if($el.hasClass(this.initDOMClass)) {
      $el.removeClass(this.initDOMClass);
      $el.trigger('zoom.destroy');
    }
    if(this.additionalClass) {
      $el.removeClass(this.additionalClass);
    }
  }


}