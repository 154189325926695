

export default class SearchBarAutocomplete {
  constructor({url, appendTo, formatFunction, inputEl, limit = 5, keyStrokeTimeout = 400}) {
    this.url = url;
    this.$appendTo = appendTo;
    this.formatFunction = formatFunction;
    this.$inputEl = inputEl;
    this.timeoutId = false;
    this.limit = limit;
    this.keyStrokeTimeout = keyStrokeTimeout;
    this.wrapperClass = 'autocomplete';
    this.setEvets();
  }

  setEvets() {
    this.$inputEl.on('keyup', (e) => {
      const val = this.$inputEl.val();

      if(this.timeoutId) {
        clearTimeout(this.timeoutId);
      }

      this.timeoutId = setTimeout(() => this.handleAjax(val), this.keyStrokeTimeout);
    })
    this.handleBlurEvent();
  }

  handleBlurEvent() {
    const self = this;
    $('body').on('click', ({target}) => {
      const $target = $(target);
      if(!$target.closest(self.$appendTo).length) {
        self.removeRestul();
      }
    })
  }

  handleAjax(val) {
    const self = this;
    $.post(this.url, {
      s: val,
      resultsPerPage: this.limit
    }, null, 'json')
    .then(function (resp) {
      self.renderAutoCompleteHtml(self.formatFunction(resp.products))
    })
  }

  removeRestul() {
    const $oldRestul = this.$appendTo.find('.' + this.wrapperClass);

    if($oldRestul) {
      $oldRestul.remove();
    }
  }

  renderAutoCompleteHtml($list) {
    this.removeRestul();

    if($list.length === 0) {
      return;
    }

    const $wrapper = $('<div>');

    $wrapper.addClass(this.wrapperClass)
      .append($list)
      .appendTo(this.$appendTo);
  }


}