function getStorageAvailable() {
  var test = "foo";
  var storage = window.localStorage || window.sessionStorage;

  try {
    storage.setItem(test, test);
    storage.removeItem(test);
    return storage;
  } catch (error) {
    return null;
  }
}

$(document).ready(function () {
  if ($(".product-list-display").length) {
    prestashop.bindGrid();
  }
});

prestashop.bindGrid = function () {
  let storage = false;

  if (typeof getStorageAvailable !== "undefined") {
    storage = getStorageAvailable();
  }

  if (!storage) {
    return;
  }

  var view = $.totalStorage("display");

  if (!view && typeof displayList != "undefined" && displayList) {
    view = "list";
  }

  if (view && view != "grid") {
    prestashop.displayType(view);
  } else {
    $(".display").find(".show_grid").addClass("selected");
  }

  $(document).on("click", "#grid a", (e) => {
    e.preventDefault();
    if ($("#js-product-list .products").hasClass("grid")) {
      return;
    }
    prestashop.displayType("grid");
  });

  $(document).on("click", "#list a", (e) => {
    e.preventDefault();
    if ($("#js-product-list .products").hasClass("list")) {
      return;
    }
    prestashop.displayType("list");
  });
};

prestashop.displayType = function (view) {
  if (view == "list") {
    $("#products .products").removeClass("grid").addClass("list");
    $("#products .products article").each(function (index, element) {
      const $el = $(element);

      const classes = $el.attr("class").split(/\s+/);

      const gridClasses = classes.filter(function (ele) {
        if (ele.slice(0, 3) == "col") {
          return ele;
        }
      });

      $el
        .removeClass(gridClasses.join(" "))
        .addClass("col-xs-12 product-miniature--list")
        .attr("data-grid", gridClasses.join(" "));

      var html = "";
      html += '<div class="product-miniature__container">';
      html += '<div class="row">';
      html += '<div class="col-xl-3 col-xs-4">';
      html += `<div class='product-miniature__thumb'>${$el
        .find(".product-miniature__thumb")
        .html()}</div>`;
      html += "</div>";
      html += '<div class="col-xl-9 col-xs-8">';
      html += `<h2 class="product-miniature__title">${$el
        .find(".product-miniature__title")
        .html()}</h2>`;
      const $prodDesc = $el.find(".product-miniature__desc");
      if ($prodDesc.length) {
        html += `<div class="product-miniature__desc">${$prodDesc.html()}</div>`;
      }
      const $prodPricing = $el.find(".product-miniature__prices");
      if ($prodPricing.length) {
        html += `<div class="product-miniature__prices">${$prodPricing.html()}</div>`;
      }
      html += `<div class='product-miniature__view-btn'>${$el
        .find(".product-miniature__view-btn")
        .html()}</div>`;
      html += "</div>";
      html += "</div>"; //row close
      html += "</div>"; //thumbnail close

      $el.html(html);
    });

    $(".show_list").addClass("selected");
    $(".show_grid").removeClass("selected");
    $.totalStorage("display", "list");
  } else {
    $("#products .products").removeClass("list").addClass("grid");
    $("#products .products article").each(function (index, element) {
      const $el = $(element);

      $el
        .removeClass("col-xs-12 product-miniature--list")
        .addClass($el.attr("data-grid"));

      var html = "";
      html += '<div class="product-miniature__container">';
      html += `<div class='product-miniature__thumb'>${$el
        .find(".product-miniature__thumb")
        .html()}</div>`;
      html += `<h2 class="product-miniature__title">${$el
        .find(".product-miniature__title")
        .html()}</h2>`;
      const $prodDesc = $el.find(".product-miniature__desc");
      if ($prodDesc.length) {
        html += `<div class="product-miniature__desc">${$prodDesc.html()}</div>`;
      }
      const $prodPricing = $el.find(".product-miniature__prices");
      if ($prodPricing.length) {
        html += `<div class="product-miniature__prices">${$prodPricing.html()}</div>`;
      }
      html += `<div class='product-miniature__view-btn'>${$el
        .find(".product-miniature__view-btn")
        .html()}</div>`;
      html += "</div>"; //thumbnail close

      $el.html(html);
    });

    $(".show_grid").addClass("selected");
    $(".show_list").removeClass("selected");
    $.totalStorage("display", "grid");
  }
};
